import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Fuse from 'fuse.js'; // For fuzzy search
import './Dashboard.css';
import icon from "../assets/images/icon.png"; // Adjust the path as needed
import ingredientData from './data/ingredients.json'; // Local ingredient database

function Dashboard() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [attachment, setAttachment] = useState(null);
  const [ingredientList, setIngredientList] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [currentConversation, setCurrentConversation] = useState(null);
  const [conversationTitle, setConversationTitle] = useState('Untitled Conversation');
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ingredientDatabase, setIngredientDatabase] = useState(ingredientData);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [instacartLink, setInstacartLink] = useState(''); // Instacart link for modal

  const chatHistoryRef = useRef(null);
  const promptsScrollRef = useRef(null);

  const suggestedPrompts = [
    'Can you ask me questions to prepare a meal plan for me.',
    'Let’s do budget-friendly meals with ingredients I have on hand.',
    'Can we create a mix of breakfasts, lunches, and dinners for the week?',
    'Let’s finalize my ingredient list and remove basic household ingredients.',
  ];

  const fuse = new Fuse(ingredientDatabase, {
    keys: ['name'],
    threshold: 0.4, // Adjust sensitivity (lower = stricter matches)
    includeScore: true,
  });

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    const savedConversations = JSON.parse(localStorage.getItem('conversations')) || [];
    setConversations(savedConversations);

    if (savedConversations.length > 0) {
      const latestConversation = savedConversations[0];
      setMessages(latestConversation.messages);
      setCurrentConversation(latestConversation.id);
      setConversationTitle(latestConversation.title);
    }
  }, []);

  useEffect(() => {
    if (currentConversation) {
      const updatedConversations = conversations.map((conv) =>
        conv.id === currentConversation ? { ...conv, messages } : conv
      );
      setConversations(updatedConversations);
      localStorage.setItem('conversations', JSON.stringify(updatedConversations));
    }
  }, [messages]);

  useEffect(() => {
    if (isModalOpen) {
      console.log("Instacart modal is opening...");
      initializeInstacartWidget();
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (ingredientList.length > 0) {
      console.log("Ingredient list updated, opening modal...");
      setIsModalOpen(true);
    }
  }, [ingredientList]);

  const handleScroll = (direction) => {
    console.log("Scrolling prompts:", direction);
    if (promptsScrollRef.current) {
      const scrollAmount = 150;
      promptsScrollRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  const handleSend = async () => {
    if (!input.trim() && !attachment) return;

    console.log("Sending message:", input);

    const newMessage = {
      role: 'user',
      content: input,
      attachment: attachment ? attachment.name : null,
    };

    const updatedMessages = [...messages, newMessage];
    setMessages(updatedMessages);
    setInput('');
    setAttachment(null);

    try {
      console.log("Sending to backend API...");
      const response = await axios.post('/api/chat', {
        messages: updatedMessages.map((msg) => ({
          role: msg.role,
          content: msg.content,
        })),
      });

      console.log("Response from API:", response.data);

      const assistantResponse = {
        role: 'assistant',
        content: response.data.content,
      };

      setMessages((prevMessages) => [...prevMessages, assistantResponse]);
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: 'assistant', content: 'Error: Unable to fetch response.' },
      ]);
    }
  };

  const fetchIngredientListFromOpenAI = async () => {
    const clarificationMessage = `Can you reformat the following list of ingredients into the format:
      - <quantity> <unit of measure> <ingredient name>. Quantities: Always include a quantity (e.g., 1 each, 2 tablespoons).
Units: Avoid non-standard units like large or fresh. Use specific units like each, teaspoon, or cup.
No Empty Fields: Avoid leaving quantities or units blank. No title, or introduction, just the list of ingredients. Unit of Measure should be like "each" or "cup" or "lb" or "teaspoon" avoid things like handleful as a measurement. Example: 1 Cup Lentils. 1.5 cup lentils. 1 each doritos. Instead of half, say .5. Use exact numbers. No optionals in the list, add them. No paratheses. No Description words (avoid adjectives, like small or large).`;

    const userMessage = {
      role: 'user',
      content: clarificationMessage,
    };

    console.log("Sending automatic message:", userMessage);

    setMessages((prevMessages) => [...prevMessages, userMessage]);

    // Wait for state update before sending API request
    await new Promise((resolve) => setTimeout(resolve, 0));

    try {
      console.log("Sending request to OpenAI...");
      const response = await axios.post('/api/chat', {
        messages: [
          ...messages,
          userMessage,
        ].map((msg) => ({
          role: msg.role,
          content: msg.content,
        })),
      });

      console.log("Response from OpenAI:", response.data.content);
      return response.data.content;
    } catch (error) {
      console.error("Error fetching response from OpenAI:", error);
      return null;
    }
  };

  const initializeInstacartWidget = () => {
    console.log("Initializing Instacart widget...");
    const scriptId = "shop-with-instacart-widget-script";
  
    // Remove any existing script
    const existingScript = document.getElementById(scriptId);
    if (existingScript) {
      existingScript.remove();
    }
  
    // Create the widget script
    const script = document.createElement("script");
    script.id = scriptId;
    script.src = "https://widgets.instacart.com/widget-bundle.js";
    script.async = true;
  
    script.onload = () => {
      console.log("Instacart widget script loaded successfully.");
  
      const widgetDiv = document.getElementById("shop-with-instacart-v1");
      if (widgetDiv) {
        // Ensure recipeSourceUrl includes '/store'
        const recipeSourceUrl = `${window.location.origin}${window.location.pathname}`;
        widgetDiv.setAttribute("data-recipeSourceUrl", recipeSourceUrl);
        widgetDiv.setAttribute("data-source_origin", window.location.origin);
  
        // Validate and set data-ingredients
        if (ingredientList.length > 0) {
          widgetDiv.setAttribute("data-ingredients", JSON.stringify(ingredientList));
          console.log("Widget initialized with data-ingredients:", ingredientList);
        } else {
          console.error("Ingredient list is empty or invalid.");
        }
      } else {
        console.error("Instacart widget div not found.");
      }
    };
  
    script.onerror = () => {
      console.error("Failed to load the Instacart widget script.");
    };
  
    document.body.appendChild(script);
  };
  
  

    const generateRecipeSchema = async () => {
        console.log("Generating recipe schema...");
        setIsLoading(true);
  
    const formattedResponse = await fetchIngredientListFromOpenAI();
    if (!formattedResponse) {
      alert("Could not retrieve formatted ingredient list. Please try again.");
      setIsLoading(false);
      return;
    }
  
    // Parse ingredients
    const parsedIngredients = parseIngredientList(formattedResponse);
  
    // Log rejected ingredients for debugging
    if (parsedIngredients.length === 0) {
      console.error("No valid ingredients found for Instacart.");
      alert("No valid ingredients found. Please check the input.");
      setIsLoading(false);
      return;
    }
  
    // Map to Instacart format
    const instacartDataIngredients = parsedIngredients.map((ingredient) =>
      `${ingredient.quantity || ""} ${ingredient.unit || ""} ${ingredient.name}`.trim()
    );
  
    console.log("Instacart data-ingredients:", instacartDataIngredients);
  
    setIngredientList(instacartDataIngredients);
    setIsLoading(false);
  
    // Inject schema
    const schemaScript = document.getElementById("recipe-schema");
    if (schemaScript) {
      schemaScript.remove();
    }
  
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.id = "recipe-schema";
    script.text = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "Recipe",
      "name": "Ingredients for your Meal Plan",
      "recipeIngredient": instacartDataIngredients,
    });
  
    document.head.appendChild(script);
    console.log("Recipe schema injected:", script.text);
  
    setIsLoading(false);
  };
  
  const parseIngredientList = (formattedResponse) => {
    console.log("Parsing formatted response:", formattedResponse);
  
    const lines = formattedResponse.split("\n");
    const ingredients = [];
  
    for (const line of lines) {
      // Ignore empty lines
      if (!line.trim()) {
        continue;
      }
  
      // Match ingredient lines
      const match = line.match(/^\s*[-•*]?\s*(\d+\.?\d*\/?\d*)?\s*(\w+)?\s*(.+)$/i);
      if (match) {
        const quantity = match[1]?.trim() || "";
        const unit = match[2]?.trim() || "";
        const name = match[3]?.trim() || "";
  
        // Add only if `name` is valid
        if (name.length > 0) {
          ingredients.push({ name, quantity, unit });
        } else {
          console.error("Invalid ingredient (missing name):", line);
        }
      } else {
        console.error("Invalid ingredient format (unmatched):", line);
      }
    }
  
    console.log("Parsed ingredients:", ingredients);
    return ingredients;
  };
  
  

  const startNewConversation = () => {
    console.log("Starting a new conversation...");
    const newConversation = {
      id: Date.now(),
      title: 'Untitled Conversation',
      messages: [
        {
          role: 'assistant',
          content: `WELCOME TO AISLE! Let's get started on creating your personalized meal plan and grocery list.`,
        },
      ],
    };

    setConversations([newConversation, ...conversations]);
    setMessages(newConversation.messages);
    setCurrentConversation(newConversation.id);
    setConversationTitle('Untitled Conversation');
    setIsEditingTitle(true);
  };

  return (
    <div className="dashboard">
      <div className="sidebar">
        <button className="new-convo-button" onClick={startNewConversation}>
          + New Conversation
        </button>
        {conversations.map((conv) => (
          <div
            key={conv.id}
            className={`conversation-item ${conv.id === currentConversation ? 'active' : ''}`}
            onClick={() => {
              console.log("Switching conversation:", conv.title);
              setMessages(conv.messages);
              setCurrentConversation(conv.id);
              setConversationTitle(conv.title);
            }}
          >
            {conv.title}
          </div>
        ))}
      </div>

      <div className="chat-container">
        <div className="conversation-header">
          <h2 className="conversation-title">{conversationTitle}</h2>
        </div>

        <div className="chat-history" ref={chatHistoryRef}>
          {messages.map((msg, index) => (
            <div key={index} className={`message ${msg.role}`}>
              {msg.content.split('\n').map((line, i) => (
                <p key={i}>{line}</p>
              ))}
            </div>
          ))}
          {isLoading && <div className="loading">Loading...</div>}
        </div>

        <div className="suggested-prompts">
          <button className="scroll-arrow" onClick={() => handleScroll('left')}>
            ❮
          </button>
          <div className="prompts-scroll" ref={promptsScrollRef}>
            {suggestedPrompts.map((prompt, index) => (
              <button key={index} className="prompt-button" onClick={() => setInput(prompt)}>
                {prompt}
              </button>
            ))}
          </div>
          <button className="scroll-arrow" onClick={() => handleScroll('right')}>
            ❯
          </button>
        </div>

        <div className="chat-input">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSend();
              }
            }}
            placeholder="Type your message..."
          />
          <button onClick={handleSend}>Send</button>
          <button onClick={generateRecipeSchema}>Let's Order!</button>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <img src={icon} alt="Your Logo" className="modal-icon" />
            <h3>Your Cart is Ready</h3>
            <p>Click below to shop for your ingredients:</p>

            <div
                id="shop-with-instacart-v1"
                data-affiliate_id="5292"
                data-source_origin="affiliate_hub"
                data-recipeSourceUrl={`${window.location.origin}${window.location.pathname}`}
                data-name="Ingredients for your Meal Plan"
                data-ingredients={JSON.stringify(ingredientList)}
            ></div>


            <button onClick={() => setIsModalOpen(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
