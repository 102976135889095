import React, { useState } from 'react';

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here, you would authenticate the user with your backend or authentication service
    console.log('Logging in with:', { email, password });
  };

  const handleForgotPassword = () => {
    // Add functionality to handle password reset (e.g., link to a "Forgot Password" page)
    alert('Redirect to password reset page or send password reset email.');
  };

  return (
    <div>
      <h2>Log In</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Email:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </label>
        <br />
        <label>
          Password:
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </label>
        <br />
        <button type="submit">Log In</button>
      </form>
      <button onClick={handleForgotPassword}>Forgot Password?</button>
    </div>
  );
}

export default LoginPage;
