import React from 'react';
import { Navigate } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Auth0Provider, withAuthenticationRequired } from "@auth0/auth0-react";
import Navbar from './components/Navbar';
import AuthPage from './components/AuthPage';
import SignUpPage from './components/SignUpPage';
import LoginPage from './components/LoginPage';
import Dashboard from './components/Dashboard';
import Home from './components/Home';
import './App.css'; // Ensure you have App.css for styling

const ProtectedDashboard = withAuthenticationRequired(Dashboard, {
  onRedirecting: () => <div>Loading...</div>,
});

function App() {
  return (
    <Auth0Provider
      domain="dev-tn51t40o8lrhhvl0.us.auth0.com" // Replace with your Auth0 domain
      clientId="oY1UtHyQL67Tmdsy9shdy8sMCwFZ4JED" // Replace with your Auth0 client ID
      authorizationParams={{
        redirect_uri: `${window.location.origin}`,
      }}
    >
      <Router>
        {/* Navbar with the updated style and links */}
        <Navbar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/auth" element={<AuthPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/dashboard" element={<ProtectedDashboard />} />
        </Routes>
      </Router>
    </Auth0Provider>
  );
}

export default App;

