import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import companyName from '../assets/images/company-name.png';
import './Navbar.css';

const Navbar = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const navigate = useNavigate();

  return (
    <div className="navbar">
      <div className="logo" onClick={() => navigate('/')}>
        <img src={companyName} alt="AISLE" className="navbar-company-name" />
      </div>

      <nav>
        <Link to="/">Home</Link>
        <Link to="/dashboard">Create Your Meals</Link>
        {isAuthenticated ? (
          <button onClick={() => logout({ returnTo: window.location.origin })}>
            Log Out
          </button>
        ) : (
          <button onClick={() => loginWithRedirect()}>
            Log In
          </button>
        )}
      </nav>
    </div>
  );
};

export default Navbar;
