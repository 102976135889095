import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import companyName from '../assets/images/company-name.png'; // Path to your company name image
import mainIcon from '../assets/images/logo.png'; // Main icon for the shopping cart bot logo

function Home() {
  return (
    <div className="home-container">
      <div className="content-wrapper">
        {/* Left Section with Icon */}
        <div className="left-section">
          <img src={mainIcon} alt="AISLE Bot Logo" className="bot-logo" />
        </div>

        {/* Divider */}
        <div className="divider"></div>

        {/* Right Section with Text and Company Name */}
        <div className="right-section">
          <img src={companyName} alt="AISLE Company Name" className="company-name-logo" />
          <p className="intro-text">
            Click the button to start creating your meal plan and get it delivered to your front door in just a few minutes.
          </p>
          <Link to="/auth">
            <button className="start-button">Get Started with AISLE</button>
          </Link>
          <p className="additional-info">
            Explore meal plans tailored to your preferences, and let AISLE help you shop for ingredients effortlessly.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
