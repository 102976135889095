import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
    domain="dev-tn51t40o8lrhhvl0.us.auth0.com"
    clientId="oY1UtHyQL67Tmdsy9shdy8sMCwFZ4JED"
    redirectUri={`${window.location.origin}/dashboard`} // Redirect to /dashboard after login
  >
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Auth0Provider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
