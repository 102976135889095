import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

function AuthPage() {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard'); // Redirect to Dashboard if authenticated
    }
  }, [isAuthenticated, navigate]);

  return (
    <div>
      <h2>Welcome! Please Sign Up or Log In</h2>
      <button onClick={() => loginWithRedirect({ screen_hint: 'signup' })}>Sign Up</button>
      <button onClick={() => loginWithRedirect()}>Log In</button>
    </div>
  );
}

export default AuthPage;
